/* Component Dependencies */
var adaptiveHtmlHeadTemplate = require('templates/adaptiveHtmlHead.hbs');
var AriesComponent = require('libs/aries-component');

AriesComponent.create({
  type: 'adaptiveHtmlHead',
  template: {
    'adaptiveHtmlHead': adaptiveHtmlHeadTemplate
  }
});
